/* Container for the feedback form and list */
.feedbackContainer {
  max-width: 800px; /* Adjust width as needed */
  margin: 0 auto; /* Centers the container horizontally */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.feedbackTitle {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Form styling */
.feedbackForm {
  display: grid;
  gap: 20px;
}

/* Form section headers */
.feedbackSectionHeader {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 10px;
}

/* Label styling */
.feedbackLabel {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 8px;
  display: block;
}

/* Select and text area styling */
.feedbackSelect,
.feedbackTextarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Select specific styling */
.feedbackSelect {
  background-color: #fff;
}

/* Text area specific styling */
.feedbackTextarea {
  min-height: 100px;
  resize: vertical;
}

/* Button styling */
.feedbackSubmitButton {
  padding: 12px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedbackSubmitButton:hover {
  background-color: #0056b3;
}

/* Feedback list styling */
.feedbackList {
  margin-top: 20px;
}

/* Feedback list item styling */
.feedbackListItem {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedbackListItem strong {
  color: #333;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .feedbackContainer {
    padding: 15px;
  }

  .feedbackForm {
    gap: 15px;
  }

  .feedbackSelect,
  .feedbackTextarea {
    font-size: 0.9rem;
  }

  .feedbackSubmitButton {
    font-size: 0.9rem;
  }
}
