
/* Ensure body background is white */
body {
  background-color: #ffffff !important; /* White background for the body */
}

/* General profile card styling */
.profile-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Account profile card */
.profile-card.account {
  @apply border border-gray-200;
  padding: 2rem;
}

.profile-card.account h2 {
  @apply text-gray-900;
}

/* Sprint program card */
.profile-card.internshipProgram {
  @apply border border-gray-100;
  padding: 1.5rem;
  background-color: #0e0e0e; /* Very light background */
}

.profile-card.internshipProgram h2 {
  @apply text-gray-700;
}

.profile-card.internshipProgram .internship-content {
  @apply text-gray-500;
}

/* Sidebar Styling */

.internshipProgram {
  display: flex;
  align-items: flex-start;
}
.internshipProgram img {
  margin-left: 16px; /* Adjust spacing between image and content */
}

.aicteIntern-container {
  width: 100%;
  max-width: 1200px; /* Adjust width as needed */
  margin: 0 auto;
}
/* Adjust the sidebar content when expanded */
.sidebar-content {
  transition: margin-left 0.3s, padding 0.3s;
}

.sidebar-content.minimized {
  margin-left: -16rem; /* Hide the sidebar completely when minimized */
  padding: 0 1rem;
}

/* Adjust the position of the bar icon */
.toggle-bar-icon {
  transition: transform 0.3s;
}

.toggle-bar-icon.minimized {
  transform: translateX(-100%); /* Move to the right when maximized */
}
/* Add this to your CSS file */
/* Add this to your CSS file */
.minimized-sidebar {
  width: 40px; /* adjust the width to your liking */
  overflow: hidden;
  padding: 0;
}

.minimized-sidebar button {
  margin-bottom: 4px;
}

.content-sidebar {
  transition: width 0.3s;
  flex: 1;
  padding: 16px;
}

.content-sidebar.minimized {
  width: 0;
  overflow: hidden;
  padding: 0;
}
body {
  background-color: #ffffff; /* White background for the body */
}
.enroll-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px; /* Adjust as needed */
}

.thank-you-message {
  color: green;
  font-weight: bold;
  margin-top: 10px; /* Space between button and message */
}
