/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Container for the form and background */
.access-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('https://iimstc.com/wp-content/uploads/slider/cache/e0a4105f14d43d1eae165e5b1f24238b/slider23.jpg') no-repeat center center/cover;
  background-size: cover;
}


.access-form {
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 8px;
  box-shadow: none; 
  border: none; 
  width: 100%;
  max-width: 720px;
  max-height: 90vh;
  color: #fff;
  overflow-y: auto;
}


/* Flexbox rows for form fields */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 30px; 
  margin-bottom: 12px; 
}

/* Flexbox item */
.form-group {
  flex: 1;
  min-width: calc(50% - 15px); 
}

/* Label for each form field */
.form-label {
  margin-bottom: 4px; 
  font-size: 0.9rem;
}

/* Input field styling */
.form-input {
  padding: 6px; 
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  margin-top: 4px; 
}

/* Buttons container */
.form-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 15px; 
}

/* Button styles */
.form-btn {
  padding: 8px 12px; 
  border-radius: 4px;
  font-size: 0.85rem; 
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
}

/* Submit button */
.form-btn-submit {
  background-color: #2563eb;
  color: #ffffff;
  border: none;
}

.form-btn-submit:hover {
  background-color: #1d4ed8;
}

/* Go Back button */
.form-btn-go-back {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
}

.form-btn-go-back:hover {
  background-color: #f5f5f5;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    min-width: 100%; 
  }

  .form-btn-container {
    flex-direction: column; 
    gap: 10px;
  }
}

/* Larger text for legends */
.form-legend {
  font-size: 20px; 
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}
.form-section{
  border: none;
}

/* Adjust the button container for positioning */
.access-form-btn-container {
  display: flex;
  justify-content: flex-end; 
  margin-top: 15px; 
  gap: 10px;
}

/* Style for the Submit button to make it smaller */
.access-form-btn-submit {
  background-color: #2563eb; 
  color: #ffffff; 
  border: none; 
  padding: 4px 8px; 
  font-size: 1rem; 
  border-radius: 4px; 
  cursor: pointer; 
}

/* Hover effect for Submit button */
.access-form-btn-submit:hover {
  background-color: #1d4ed8;
}

/* Go Back button */
.access-form-btn-go-back {
  background-color: #ffffff; 
  color: #000000; 
  border: 2px solid #000000;
  padding: 4px 8px;
  font-size: 1rem; 
  border-radius: 4px; 
  cursor: pointer; 
}

/* Hover effect for Go Back button */
.access-form-btn-go-back:hover {
  background-color: #f5f5f5; 
}
