
/* Container for scrolling */
.scroll-container {
  position: relative;
  height: 100vh; /* or any height as needed */
  overflow: auto; /* enables scrolling */
}

/* Background Styles */
.backgrounds {
  @apply absolute bg-cover bg-no-repeat;
  background-image: url('../img/manage.jpeg');
  background-attachment: fixed;
  background-size: 65% 80%;
  background-position: left center;
  height: 100%; /* Ensure background covers the full height of the container */
  width: 150vw; /* Allows horizontal scrolling */
  top: 0;
  left: 0;
  z-index: -1;
}

.backs {
  @apply absolute inset-0 bg-cover bg-center bg-no-repeat;
  background-color: custom-teal;
  transform: scaleX(-1);
  height: 100%; /* Ensure background covers the full height of the container */
  width: 100%; /* Cover the full width of the container */
  z-index: -1;
}
