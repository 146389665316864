.main-container {
  width: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  padding: 20px;
  height: 100%;
}

.international-first-row h1 {
  text-align: center;
  margin-bottom: 20px;
}

.international-first-row h1 {
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
}

.model-buttons {
  display: flex;
  justify-content: center;
  gap: 0;
  margin-top: 20px;
}

.model-button {
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #b969f3;
}

.model1 {
  background-color: #204598;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: white;

}

.model2 {
  background-color: #204598;
  border-radius: 0;
  color: white;
}

.model3 {
  background-color: #204598;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: white;
}

.model-button:hover,
.model-button:focus {
  background-color: #7497e7;
  color: black;
}

.second-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.content-column {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 52%);
}

.content-grid h2,
.content-grid h3 {
  margin-bottom: 10px;
  font-weight: bolder;
  font-size: 20px;
}

.content-grid ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
}

.image-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-column img {
  width: 100%;
  border-radius: 8px;
}

#image-container-1 {
  display: block;
}

.column-content {
 
  padding: 10px;
  margin-left: 15%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.column-content h2, h3 {
  margin-bottom: 20px;
  color: #204598;
}

.column-content p {
  margin-bottom: 20px;
  color: black;
}

.first-row p {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 20px;
  color: black;
}

li {
  margin-bottom: 15px;
}

.column-content button {
  background-color: #007bff;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.column-content button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.column-content button:focus {
  outline: none;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .second-row {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .content-column, .image-column {
    padding: 15px;
  }

  .model-button {
    font-size: 18px;
  }

  .first-row p {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 768px) {
  .main-container {
    padding: 10px;
  }

  .model-button {
    font-size: 16px;
    padding: 8px 15px;
  }

  .international-first-row h1 {
    font-size: 24px;
  }

  .first-row p {
    font-size: 18px;
  }

  .column-content h2, .column-content h3 {
    font-size: 20px;
  }

  .content-grid ul {
    font-size: 18px;
  }

  .column-content button {
    padding: 8px 15px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .model-buttons {
    flex-direction: column;
  }

  .model-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .international-first-row h1 {
    font-size: 25px;
  }

  .first-row p {
    font-size: 16px;
  }

  .content-column, .image-column {
    padding: 10px;
  }

  .column-content h2, .column-content h3 {
    font-size: 18px;
  }

  .column-content button {
    font-size: 0.8em;
    padding: 7px 12px;
  }
}
.prog-sub-heading{
  font-size:24px;
}
.prog-sub-heading{
  font-size:16px;
}
.first-row-heading{
  font-size: 28px;
  font-weight: bolder;
}


@media (max-width: 641px) {
  .model-button {
    width: auto; /* Reset to auto width for larger screens */
    margin: 5px; /* Margin between buttons */
    border-radius: 10px;
  }
}
