body{
  background-color: rgb(18, 18, 40);
}
.std-main-container {
          display: block;
          padding: 20px;
          width: 100%;
          height: 80%;
      }
      /* Card Container (Single Row with Flexbox) */
      .std-card-container {
          display: flex;
          flex-wrap: nowrap;
          /* Ensures cards stay in a single row */
          overflow-x: auto;
          /* Allows horizontal scrolling if the cards overflow */
          gap: 20px;
          /* Adds space between cards */
      }
      .std-card {
          background-color: #204598;
          padding: 20px;
          min-width: 170px;
          /* Ensures cards have a minimum width */
          flex-shrink: 0;
          /* Prevents cards from shrinking */
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          text-align: center;
      }

      .std-card h3 {
          margin-bottom: 10px;
          color:white;
          font-weight: bolder;
      }

      .std-view-btn,
      .std-edit-btn {
          background-color: #007bff;
          color: white;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
          cursor: pointer;
      }

      .std-edit-btn {
          background-color: #28a745;
          margin-left: 10px;
      }

      /* Table Container */
      .std-table-container {
          width: 75%;
          display: none;
          margin-top: 20px;
      }

      .std-table-container-education {
          width: 85%;
          display: none;
          /* Hidden by default */
          margin-top: 20px;
      }

      .std-details-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
          background-color: #f9f9f9;
      }

       th,
      td {
          padding: 8px;
          border: 1px solid #ddd;
          text-align: left;
      }

       th {
          background-color: #f2f2f2;
      }

      /* Popup Form */
      .std-popup-form {
          display: none;
          /* Hidden by default */
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          justify-content: center;
          align-items: center;
      }

      .std-form-container {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          width: 100%;

      }

      .std-form-field {
          margin-bottom: 15px;
      }

      .std-form-field label {
          display: block;
          margin-bottom: 5px;
          color: white;
      }

      .std-form-field input {
          width: 90%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
      }
      .std-form-field textarea,
      select {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
      }
      

      .std-form-buttons {
          display: flex;
          justify-content: flex-end;

          gap: 20px;
      }
      .std-save-btn,
      .std-back-btn {
          padding: 10px;
          border: none;
          color: white;
          cursor: pointer;
          border-radius: 5px;
      }

      .std-save-btn {
          background-color: #007bff;
      }

      .std-back-btn {
          background-color: #dc3545;
      }

      .std-view-icon,
      .std-edit-icon {
          font-size: 20px;
          cursor: pointer;
          color: white;
      }

      .std-view-icon {
          margin-right: 20px;
          /* Increase the gap between the icons */
      }

      .std-edit-icon {
          color: white;
      }

      .std-table-container {
          margin-top: 20px;
      }

    

       td {
          border: 1px solid #ddd;
          padding: 8px;
      }

      table td[colspan="3"] {
          width: calc(66.66%);
          /* Ensures it spans across two columns */
      }

      .std-table-container {
          margin-top: 20px;
      }

      .std-table-container table {
          width: 80%;
          border-collapse: collapse;
          background-color: #f9f9f9;
          margin-left: 80px;
      }

       table td {
          border: 1px solid #ddd;
          padding: 8px;
          height: 50px;
          /* Adjust row height */
      }

      .std-table-data tbody tr:nth-child(even) {
          background-color: #f2f2f2;
          /* Color for even rows */
          color: black;
      }

      .std-table-data tbody  tr:nth-child(odd) {
          background-color: #204598;
          color: white;
          /* Color for odd rows */
      }

      table td[colspan="3"] {
          width: calc(66.66%);
          /* Ensures it spans across two columns */
      }

      /* Popup form container */
.std-popup-form {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 999;
}
/* Form container */
.std-form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px; /* Increased width */
  max-width: 90%; /* Max width for smaller screens */
  max-height: 80%; /* Ensures the form doesn't take up the whole screen */
  overflow-y: auto; /* Make the content scrollable */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.std-form-container form{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.std-form-container h3{
  text-align: center;
}

/* Ensure smooth scrolling inside the form */
.std-form-container::-webkit-scrollbar {
  width: 8px;
}

.std-form-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

/* Form fields */
.std-form-field {
  margin-bottom: 15px;
}

.std-form-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

.std-form-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Buttons */
.std-form-buttons {
  display: flex;
  justify-content: space-between;
}

.std-save-btn, .std-back-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.std-back-btn {
  background-color: #f44336; /* Red for the back button */
}

.std-save-btn:hover, .back-btn:hover {
  opacity: 0.8;
}

.std-table-container,
.std-popup-form {
display: none; /* Hidden by default */
}



.std-popup-form {
display: flex; /* Center the form */
justify-content: center;
align-items: center;
position: fixed; /* Optional: To overlay the form on top */
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.std-form-container {
background: white;
padding: 20px;
border-radius: 8px;
width: 100%;

}

.std-show {
display: block; /* Show when active */
}

.std-table-content h3{
color: black;
font-size:20px;
margin-top:20px;
font-weight: bolder;
}

.std-table-data {
  width: 94%;
  border-collapse: separate; 
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden; 
  box-shadow: 0 4px 8px rgb(0 0 0 / 85%);
}
.std-form-container h3 {
  color: white;
  font-weight: bolder;
  font-size: 20px;
}

.std-form-container {
  margin-top: 5%;
  padding: 20px;
  background-color: #204598;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 85%);
  width: 100%;
}

.std-form-container h3 {
  margin-bottom: 20px;
}

.std-form-table {
  width: 100%;
  border-collapse: collapse;
}

.std-form-table td {
  padding: 10px;
  border: 1px solid #333; /* Dark border color */
}

.std-form-table label {
  display: block;
  font-weight: bold;
}

.std-form-buttons {
  margin-top: 20px;
}

.std-form-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

.std-save-btn {
  background-color: #4CAF50; /* Green */
}

.std-back-btn {
  background-color: #f44336; /* Red */
}


.std-form-container form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; /* Space between columns and rows */
}

.std-form-field {
  display: flex;
  flex-direction: column;
}

.std-form-buttons {
  grid-column: span 2; /* Make the buttons span both columns */
  display: flex;
  justify-content: space-between;
}

.std-form-buttons button {
  padding: 10px 20px;
  border: none;
 

  cursor: pointer;
}




input[type="file"] {
  background-color: white; /* Set background to white */
}


.std-form-buttons .std-save-btn{
color: white;
}



.std-view-icon,
.std-edit-icon {
font-size: 20px;
cursor: pointer;
color: white;
transition: color 0.3s ease; /* Smooth color transition */
}

/* Hover effect for view icon */
.std-view-icon:hover {
color: #000000; /* Green color on hover */
}

/* Hover effect for edit icon */
.std-edit-icon:hover {
color: #000000; /* Orange color on hover */
}



@media (max-width: 768px) {  /* Apply fixed width only for larger screens */
  .std-main-container {
   
    padding: 1px;
   
  }

  .std-card-container {
      flex-wrap: wrap; /* Allow cards to wrap in multiple rows */
      gap: 10px;
  }

  .std-card {
      min-width: 150px;
      padding: 15px;
      font-size: 14px; /* Reduce font size for better readability */
  }

  .std-form-container {
      width: 90%; /* Reduce width for smaller screens */
      padding: 15px;
  }

  .std-form-container form {
      grid-template-columns: 1fr; /* Single-column layout for forms */
  }

  .std-form-buttons {
      
      gap: 10px;
      justify-content: center;
  }

  .std-table-container,
  .std-table-container-education {
      width: 100%; /* Full width for smaller screens */
      margin-top: 15px;
  }

  .std-form-field input,
  .std-form-field textarea,
  .std-form-field select {
      width: 100%; /* Full width form elements */
  }
}

@media (max-width: 480px) { /* For mobile sizes */
  .std-card {
      min-width: 120px;
      padding: 10px;
      font-size: 12px;
  }

  .std-table-container table {
      width: 100%; /* Table takes full width on mobile */
      margin-left: 0;
  }

  .std-popup-form {
      padding: 10px; /* Reduce padding around popup form */
  }

  .std-form-buttons {
   
      align-items: center;
      gap: 8px;
  }
}



@media (max-width: 768px) { /* Tablet and below */
  .std-form-container {
      width: 100%;
      padding: 10px;
  }

  .std-form-container form {
      display: grid;
      grid-template-columns: 1fr; /* Full width for each form field */
      gap: 15px;
  }

  .std-form-field {
      width: 100%; /* Full width of the form field */
  }

  .std-form-field input,
  .std-form-field textarea,
  .std-form-field select {
      width: 100%; /* Full width for form inputs */
      padding: 8px;
  }

  .std-form-buttons {
      
      align-items: center;
      gap: 10px;
  }
}

@media (max-width: 480px) { /* Mobile */
  .std-form-container {
      padding: 5px;
  }

  .std-form-field input,
  .std-form-field textarea,
  .std-form-field select {
      padding: 6px;
      font-size: 14px; /* Adjust font size for mobile */
  }

  .std-form-buttons {
      gap: 8px;
  }
}



@media (max-width: 768px) { /* Tablet and below */
  .std-table-container {
      width: 100%;
      overflow-x: auto; /* Enables horizontal scrolling for wider tables */
  }

  .std-table-container table {
      width: 100%;
      border-collapse: collapse;
  }

  /* Make the table rows stack into a single column */
  .std-table-container table thead {
      display: none; /* Hide table headers */
  }

  .std-table-container table tbody tr {
      display: block; /* Each row becomes a block */
      margin-bottom: 20px; /* Add some space between rows */
      border: 1px solid #ddd; /* Add border to each block for better readability */
  }

  .std-table-container table tbody tr td {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #eee; /* Add separator between items */
  }

  .std-table-container table tbody tr td:before {
      content: attr(data-label); /* Use CSS to inject the column label before each cell */
      font-weight: bold;
      margin-right: 10px;
  }
}

@media (max-width: 480px) { /* Mobile */
  .std-table-container table tbody tr td {
      padding: 8px;
      font-size: 14px;
  }

  .std-table-container table tbody tr td:before {
      font-size: 12px; /* Smaller font size for labels on mobile */
  }
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

/* Popup Content */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Popup Buttons */
.popup-buttons {
  margin-top: 20px;
}

.popup-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.popup-buttons button:focus {
  outline: none;
}

.popup-buttons .bg-green-500 {
  background-color: #4CAF50; /* Green button */
  color: white;
}

.popup-buttons .bg-red-500 {
  background-color: #F44336; /* Red button */
  color: white;
}

.popup-buttons .bg-red-500:hover {
  background-color: #d32f2f;
}

.popup-buttons .bg-green-500:hover {
  background-color: #388e3c;
}


.checkbox-container {
  display: flex;                
  align-items: center;           
  margin: 10px 0; 
  margin-top: 35px;
  
}

.checkbox-container input[type="checkbox"] {
  width: 20px;                   
  height: 20px;
  margin-right: 10px;            
}

.checkbox-container label {
  font-weight: bold;            
  font-size: 16px;               
}


/* Mobile and Tablet Media Queries */
@media screen and (max-width: 1024px) {
  .std-card-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row for tablets */
  }
}

@media screen and (max-width: 768px) {
  .std-card-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row for mobile */
  }
}

@media screen and (max-width: 480px) {
  .std-card-container {
    grid-template-columns: 1fr; /* 1 card per row for small mobile screens */
  }
}




/* Responsive Form - Single Column on Mobile */
@media screen and (max-width: 768px) {
  .std-form-container form {
    display: grid;
    grid-template-columns: 1fr; /* Single column for form fields */
  }

  .std-form-buttons {
    grid-column: span 1; /* Buttons span full width */
    display: flex;
    justify-content: space-between;
  }
}

/* Table Styles */
.std-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.std-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #f9f9f9;
}

.std-details-table th,
.std-details-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.std-details-table th {
  background-color: #f2f2f2;
}

/* Adjust Table Font Size for Mobile */
@media screen and (max-width: 768px) {
  .std-details-table th,
  .std-details-table td {
    font-size: 14px; /* Reduce font size for table on smaller screens */
  }
}





/* Reduce font size and ensure wrapping for table data in mobile view */
@media (max-width: 768px) {
.std-table-container table {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling for overflow */
    font-size: 12px; /* Reduce the font size */
}

.std-table-container td, .std-table-container th {
    white-space: normal; /* Allows text to wrap if it's too long */
    word-wrap: break-word; /* Break long words onto the next line */
}

.std-card {
    min-width: 150px;
    padding: 15px;
    font-size: 12px; /* Reduce card font size for mobile */
}

/* Ensure table fits in smaller screens */
.std-table-container {
    width: 100%;
    overflow-x: scroll; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
}

/* Table cells adjustments */
.std-details-table th, 
.std-details-table td {
    padding: 6px; /* Reduce padding for better fit */
}

.std-table-data {
    width: 100%; /* Full width on mobile */
    border-collapse: collapse;
}

/* For even and odd rows background */
.std-table-data tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.std-table-data tbody tr:nth-child(odd) {
    background-color: #204598;
    color: white;
}

/* Reduce the table header font size */
th {
    font-size: 12px;
}

td {
    font-size: 11px;
    height: auto; /* Allow the cell height to adjust automatically */
}

/* Popup form styling on mobile */
.std-form-container {
    width: 90%;
    padding: 15px;
}

.std-form-container form {
    grid-template-columns: 1fr; /* Single column layout */
}

.std-form-buttons {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}
}
@media (max-width: 640px) {
  .std-table-content {
    width: 30vh; /* Set height to 30vh for mobile */
  }
}

.std-table-container {
  width: 100%; /* Ensures full width on mobile */
  overflow-x: auto; /* Enables horizontal scrolling for wider tables */
  margin-top: 20px;
}

.std-details-table {
  width: 100%; /* Full width for table */
  border-collapse: collapse; /* Collapses borders */
  margin-top: 10px;
  background-color: #f9f9f9; /* Background color */
}

.std-details-table th,
.std-details-table td {
  padding: 8px; /* Padding for cells */
  border: 1px solid #ddd; /* Cell borders */
  text-align: left; /* Align text to the left */
  white-space: normal; /* Allows wrapping */
  word-wrap: break-word; /* Breaks long words */
}

/* Additional styles for table cells to ensure consistency */
.std-details-table th {
  background-color: #f2f2f2; /* Header background color */
  font-size: 14px; /* Adjusted font size for headers */
}

.std-details-table td {
  font-size: 12px; /* Adjusted font size for data cells */
  height: auto; /* Allows height to adjust automatically */
}

/* Specific styles for mobile devices */
@media (max-width: 768px) {
  .std-table-container {
      width: 100%; /* Ensures full width on mobile */
  }

  .std-details-table th,
  .std-details-table td {
      font-size: 12px; /* Reduce font size for mobile */
      padding: 6px; /* Reduce padding for better fit */
  }

  /* Additional styles for wrapping */
  .std-details-table td {
      white-space: normal; /* Allows text to wrap */
      word-wrap: break-word; /* Break long words */
  }
}

@media (max-width: 480px) {
  .std-table-container {
      width: 100%; /* Full width on small screens */
  }

  .std-details-table th,
  .std-details-table td {
      font-size: 10px; /* Further reduce font size for very small screens */
  }
}

/* Ensure table fits in smaller screens */
.std-table-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
  margin-top: 20px; /* Margin for spacing */
}

/* Table styling */
.std-details-table {
  width: 100%; /* Full width for table */
  border-collapse: collapse; /* Collapses borders */
  margin-top: 10px;
  background-color: #f9f9f9; /* Background color */
}

.std-details-table th,
.std-details-table td {
  padding: 8px; /* Padding for cells */
  border: 1px solid #ddd; /* Cell borders */
  text-align: left; /* Align text to the left */
  white-space: normal; /* Allows wrapping */
  word-wrap: break-word; /* Breaks long words */
}

/* For even and odd rows background */
.std-details-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.std-details-table tbody tr:nth-child(odd) {
  background-color: #204598;
  color: white;
}

/* Reduce the table header font size */
.std-details-table th {
  font-size: 14px; /* Adjusted font size for headers */
  background-color: #f2f2f2; /* Header background color */
}

.std-details-table td {
  font-size: 12px; /* Adjusted font size for data cells */
  height: auto; /* Allows height to adjust automatically */
}

/* Popup form styling on mobile */
.std-form-container {
  width: 90%;
  padding: 15px;
}

.std-form-container form {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout */
}

.std-form-buttons {
  display: flex;
 
  gap: 10px;
  justify-content: center;
}

/* Specific styles for mobile devices */
@media (max-width: 768px) {
  .std-details-table th,
  .std-details-table td {
      font-size: 12px; /* Reduce font size for mobile */
      padding: 6px; /* Reduce padding for better fit */
  }
}
.std-table-data td {
  border: 1px solid #ddd; 
  padding: 8px;
  max-width: 100px; /* Set a max-width for table cells */
  min-width: 100px;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Ensure long words break to fit the cell */
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .std-details-table th,
  .std-details-table td {
      font-size: 10px; /* Further reduce font size */
  }
}

/* Add this to your existing CSS */
.std-table-data td {
  border: 1px solid #ddd;
  padding: 8px;
  height: auto; /* Allow height to adjust automatically */
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* Ensure long words break */
  white-space: normal; /* Allow normal wrapping */
}
.std-table-data {
  width: 94%;
  border-collapse: separate; 
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden; 
  box-shadow: 0 4px 8px rgb(0 0 0 / 85%);
  margin-bottom: 30%;
}



/* Optional: If you want to ensure headers wrap as well */
.std-table-data th {
  max-width: 300px; /* Same max-width for headers */
  white-space: normal;
  word-wrap: break-word;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 20px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.popup-buttons button {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
}

.popup-buttons button:first-child {
  background-color: #ccc;
}

.popup-buttons button:last-child {
  background-color: #4CAF50;
  color: white;
}
