/* Container for the form */
.leave-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Form Header */
  .leave-form-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Container for each input field */
  .leave-input-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Style for input fields */
  input,
  select,
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Style for text area */
  textarea {
    resize: vertical;
  }
  
  /* Submit Button */
  .leave-submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
  }
  
  .leave-submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .leave-form-container {
      padding: 15px;
    }
  
    input,
    select,
    textarea {
      font-size: 14px;
    }
  
    .leave-submit-button {
      font-size: 14px;
    }
  }
  

  /* Container for the form remains unchanged */
.leave-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Form Header remains unchanged */
  .leave-form-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Container for each input field remains unchanged */
  .leave-input-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Style for input fields remains unchanged */
  input,
  select,
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Description Textarea: Make it bigger */
  textarea {
    resize: vertical;
    min-height: 150px; 
    width: 100%;      
  }
  
  /* Submit Button remains unchanged */
  .leave-submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
  }
  
  .leave-submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design remains unchanged */
  @media (max-width: 768px) {
    .leave-form-container {
      padding: 15px;
    }
  
    input,
    select,
    textarea {
      font-size: 14px;
    }
  
    .leave-submit-button {
      font-size: 14px;
    }
  }
  /* Container for the form remains unchanged */
.leave-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Form Header remains unchanged */
  .leave-form-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Container for each input field with labels aligned left */
  .leave-input-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left; /* Ensures the text aligns to the left */
  }
  
  /* Style for input fields remains unchanged */
  input,
  select,
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%; 
  }
  
  /* Description Textarea remains bigger */
  textarea {
    resize: vertical;
    min-height: 150px;
    width: 100%; 
  }
  
  /* Submit Button remains unchanged */
  .leave-submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
  }
  
  .leave-submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design remains unchanged */
  @media (max-width: 768px) {
    .leave-form-container {
      padding: 15px;
    }
  
    input,
    select,
    textarea {
      font-size: 14px;
    }
  
    .leave-submit-button {
      font-size: 14px;
    }
  }
  
  .leave-form-header {
    font-size: 2rem; 
    font-weight: bold; 
    margin: 10px 0; 
  }
  
