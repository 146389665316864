.reg-form-container {
    max-width: 70%;
    margin: auto;
    background-color: #000000ed;
    padding: 30px; /* Increased padding */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.778), 0 0 15px rgba(0, 255, 255, 0.4); /* Shadow and glowing effect */
    margin-top: 30px; /* Space at the top */
    margin-bottom: 30px; /* Space at the bottom */
}

.reg-form-section {
    margin-bottom: 30px; /* Increased space between sections */
}

.reg-form-section-title {
    font-size: 1.5rem; /* Larger font size */
    font-weight: bold;
   
    margin-bottom: 15px; /* Space below heading */
  
}

/* Adjust form-group for horizontal alignment */
.reg-form-group {
    display: flex;
    flex-direction: row; /* Align label and input horizontally */
    align-items: center; /* Vertically center items */
    margin-bottom: 20px; /* Space between form elements */
}

.reg-form-label {
    margin-right: 20px; /* Space between label and input */
    font-weight: bold;
    width: 150px; /* Fixed width for labels */
}

.reg-form-input,
input[type="date"] {
    padding: 10px; /* Increased padding */
    border-radius: 8px; /* Rounded borders */
    border: 1px solid #b2ebf2; /* Light blue border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for inputs */
    flex: 1; /* Allow input to take remaining space */
}

/* Specific styling for Date of Birth input to be a bit less than half width */
.reg-form-input input[type="date"].half-width {
    flex: 0 1 calc(27% - 20px); /* Make it approximately 40% width */
}

/* Specific styles for file input */
.reg-form-input input[type="file"] {
    border: 1px solid #b2ebf2; /* Light blue border */
    padding: 10px; /* Increased padding */
    border-radius: 8px; /* Rounded borders for file inputs */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for file inputs */
}

/* Two-column layout for form sections */
.reg-form-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.reg-form-group {
    flex: 1 1 calc(50% - 20px); /* Adjust 20px to match the gap */
}

.reg-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px; /* Space between buttons */
    margin-bottom: 30px; /* Space at the bottom */
}

/* Specific button styles */
.reg-button-submit {
    background-color: #00796b; /* Darker blue-green background */
    color: #ffffff;
    padding: 14px 28px; /* Increased padding */
    border: none;
    border-radius: 12px; /* Rounded corners */
    cursor: pointer;
    font-size: 1.1rem; /* Slightly larger font size */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Shadow for buttons */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
}

.reg-button-submit:hover {
    background-color: #004d40; /* Darker shade on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.reg-button-go-back {
    background-color: #cfd8dc; /* Light grey background */
    color: #333;
    padding: 12px 24px; /* Increased padding */
    border: none;
    border-radius: 12px; /* Rounded corners */
    cursor: pointer;
    font-size: 1.1rem; /* Slightly larger font size */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for buttons */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
}

.reg-button-go-back:hover {
    background-color: #b0bec5; /* Darker grey on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.reg-additional-links {
    text-align: center;
    margin-top: 20px; /* Space above additional links */
}

.reg-login-link {
    font-size: 1.1rem; /* Slightly larger font size */
    color: black;
}

.reg-login-link a {
    color: #00796b; /* Matching blue-green color */
    text-decoration: none;
}



.reg-login-link a:hover {
    text-decoration: underline;
}

/* Add styles for the date picker input */
.reg-react-datepicker__input-container {
    width: 100%;
}

.reg-react-datepicker__input {
    padding: 10px; /* Increased padding */
    border-radius: 8px; /* Rounded borders */
    border: 1px solid #b2ebf2; /* Light blue border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


    .reg-button-submit,
    .reg-button-go-back {
        width: 100%; /* Full width for buttons */
    }
/* Responsive Styles */
@media (max-width: 767px) {
    .reg-form-label {
        font-weight: bold;
        width: auto; /* Remove the fixed width to allow the label to adjust */
        margin-bottom: 10px; /* Space below label for better readability */
    }

    .reg-form-container {
        max-width: 90%; /* Adjust the width for mobile screens */
        padding: 15px; /* Adjust the padding for mobile */
        margin-left: 10px; /* Less margin for mobile */
        margin-right: 10PX;
    }
}
/* Adjust Document Upload section for mobile */
@media (max-width: 767px) {
    .reg-form-group {
        margin-bottom: 10px; /* Reduced margin between fields */
    }
    
    input[type="file"] {
       
        font-size: 0.7rem; /* Slightly smaller font size */
    }

    .reg-form-section {
        margin-bottom: 15px; /* Reduce margin between sections */
    }
    
    /* Specific style to make the document upload section appear earlier */
    fieldset.form-section:nth-of-type(3) {
        order: -1; /* Move the Document Upload section up */
    }
}

/* Full-page background */
.form-background {
    background: url('https://iimstc.com/wp-content/uploads/slider/cache/e0a4105f14d43d1eae165e5b1f24238b/slider23.jpg') no-repeat center center/cover;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  /* Form container with transparent black background */
  .reg-form-container {
    max-width: 70%;
    background-color: #000000ed;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    color: white; /* Text color for contrast */
  }
  
  .reg-form-section-title {
    color: #ffffff; /* Ensure text is visible */
  }
  
  /* Adjust other styles if necessary */
  .form-input {
    background-color: #ffffff;
    color: black;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 767px) {
    .reg-form-container {
      max-width: 90%;
      padding: 20px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  


  /* Button container styles */
.reg-btn-container {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 15px; /* Space between buttons */
    margin-bottom: 30px; /* Space at the bottom */
}

/* Submit Button styles */
.reg-button-submit {
    background-color: #00796b; /* Darker blue-green background */
    color: #ffffff;
    padding: 12px 24px; /* Adjusted padding */
    border: none;
    border-radius: 12px; /* Rounded corners */
    cursor: pointer;
    font-size: 1.1rem; /* Slightly larger font size */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Shadow for buttons */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
    width: auto; /* Remove full width */
}

/* Go Back Button styles */
.reg-button-go-back {
    background-color: #ffffff; /* White background */
    color: #000000; /* Black text */
    padding: 12px 24px;
    border: 2px solid #000000; /* Add a black border */
    border-radius: 12px;
    cursor: pointer;
    font-size: 1.1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    width: auto;
}

/* Hover effect for submit button */
.reg-button-submit:hover {
    background-color: #004d40; /* Darker shade on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

/* Hover effect for go-back button */
.reg-button-go-back:hover {
    background-color: #f5f5f5; /* Light grey on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.reg-login-legend-logo {
    width: 140px;  /* Adjust width as needed */
    height: 70px; /* Adjust height as needed */
    margin-right: 10px; /* Adds some space between the logo and the login text */
}


/* Form Heading Style */
.reg-form-heading {
    text-align: center;
    font-size: 2.2rem;
    color: white;
    margin-bottom: 20px; /* Space below heading */
  }
  
  .reg-form-section {
    border: none; /* This removes the border */
  }
.reg-additional-links p{
    color: white;
} 

.reg-additional-links p a{
    color: blue;
}


.reg-form-submit {
    background-color: #2563eb;
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
}







.reg-form-container {
    max-width: 70%;
    margin: auto;
    background-color: #000000ed;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.778), 0 0 15px rgba(0, 255, 255, 0.4);
    margin-top: 30px;
    margin-bottom: 30px;
}

.reg-form-section {
    margin-bottom: 30px;
}

.reg-form-section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.reg-form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.reg-form-label {
    margin-right: 20px;
    font-weight: bold;
    width: 150px;
}

.reg-form-input,
input[type="date"] {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%; /* Full width input for responsiveness */
    max-width: 400px; /* Set a max-width to prevent too large inputs */
}

/* Button Styling */
.reg-form-submit, .reg-button-go-back {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reg-button-go-back{
    padding: 10px 20px;
    background-color: #f2f0f0;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reg-form-submit:hover, .reg-button-go-back:hover {
    background-color: #0056b3;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .reg-form-container {
        max-width: 90%;
        padding: 20px;
        margin-left: 15px;
      margin-right: 15px;
    }

    .reg-form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .reg-form-label {
        margin-right: 0;
        margin-bottom: 5px;
        width: 100%;
    }

    .reg-form-input,
    input[type="date"] {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .reg-form-container {
        padding: 15px;
        margin-left: 15px;
      margin-right: 15px;
      max-width: 80%;
    }

    .reg-form-section-title {
        font-size: 1.25rem;
    }

    .reg-form-submit, .reg-button-go-back {
        width: 100%;
        padding: 15px;
    }
}
.reg-form-container {
    max-width: 70%;
    margin: auto;
    background-color: #000000ed;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.778), 0 0 15px rgba(0, 255, 255, 0.4);
    margin-top: 30px;
    margin-bottom: 30px;
}

.reg-form-section {
    margin-bottom: 30px;
}

.reg-form-section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.reg-form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.reg-form-label {
    margin-right: 20px;
    font-weight: bold;
    width: 150px;
}

.reg-form-input,
input[type="date"] {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 400px;
}

/* Button Styling */
.reg-form-submit {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reg-button-go-back{
    padding: 10px 20px;
    background-color: #f0f1f2;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reg-form-submit:hover  {
    background-color: #0056b3;
}
.reg-button-go-back:hover{
    
    background-color: #8c8c8d;
}
/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .reg-form-container {
        max-width: 90%;
        padding: 20px;
        margin-left: 15px;
      margin-right: 15px;
    }

    .reg-form-group {
        flex-direction: column; /* Stack form groups vertically */
        align-items: flex-start;
    }

    .reg-form-label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }

    .reg-form-input,
    input[type="date"] {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .reg-form-container {
        padding: 15px;
        margin-left: 15px;
      margin-right: 15px;
      max-width: 80%;
    }

    .reg-form-section-title {
        font-size: 1.25rem;
    }

    .reg-form-submit, .reg-button-go-back {
        width: 100%; /* Full-width buttons */
        padding: 15px;
    }
}
.reg-form-group {
    flex-grow: 0 !important;
}


@media (max-width: 768px) {
    .reg-form-group {
        flex: 1 1 100%; /* Full-width, single-column layout on mobile */
    }
}






/* Base Styles */
.reg-form-container {
    max-width: 70%;
    margin: auto;
    background-color: #000000ed;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.778), 0 0 15px rgba(0, 255, 255, 0.4);
    margin-top: 30px;
    margin-bottom: 30px;
    color: white; /* Text color for contrast */
}

/* Media Queries for responsiveness */
@media (min-width: 100px) and (max-width: 1224px) {
    .reg-form-container {
        max-width: 70%; /* Set width to 60% for tablets */
        padding: 20px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .reg-form-group {
        flex-direction: column; /* Stack form groups vertically */
        align-items: flex-start;
    }

    .reg-form-label {
        width: 95%;
        margin-right: 0;
        margin-bottom: 5px;
    }

    .reg-form-input,
    input[type="date"] {
        width: 95%;
        max-width: 95%;
    }
}

@media (max-width: 480px) {
    .reg-form-container {
        padding: 15px;
        margin-left: 15px;
        margin-right: 15px;
        max-width: 80%;
    }

    .reg-form-section-title {
        font-size: .9rem;
    }

    .reg-form-submit, .reg-button-go-back {
        width: 100%; /* Full-width buttons */
        padding: 15px;
    }
}
