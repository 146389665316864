/* SignatureBackgroundRemover.css */

/* Container */
.signature-container {
  text-align: center;
  padding: 20px;
  width: 100%;
}

/* Header */
.signature-header {
  color: rgb(37, 99, 235);
  font-weight: bolder;
  font-size: 28px;
}

/* Upload Section */
.signature-upload-section {
  width: 45%;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
}

.signature-upload-header {
  margin-bottom: 10px;
  font-weight: bolder;
  font-size: 14px;
  color: red;
}

.signature-upload-input {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: large;
  width: 50%;
}

.signature-upload-image-container {
  width: 400px;
  height: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.signature-upload-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signature-canvas {
  display: none;
}

.signature-button-container {
  margin-top: 20px;
}

.signature-button {
  background-color: #4caf50;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.signature-button:disabled {
  background-color: #b3b3b3;
  cursor: not-allowed;
}

.signature-button:hover:not(:disabled) {
  background-color: #45a049;
}

/* Processed Image Section */
.signature-processed-image-section {
  width: 45%;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
}

.signature-crop-header {
  margin-bottom: 30px;
  font-size: 1.4em;
  font-weight: bolder;
}

.signature-upload-header{
  margin-bottom: 10px;
    font-size: 1em;
    font-weight: bolder;
}

.signature-cropper-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
}

.signature-download-button {
  margin-left: 10px;
}

/* SignatureBackgroundRemover.css */

/* Flex container for the upload and processed image sections */
.signature-extraction-container {
  display: flex;
  justify-content: center;
}
