@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.background {
  @apply absolute bg-cover bg-no-repeat;
  background-image: url('../img/home.jpg');
  background-attachment: fixed; 
  background-size: 65% 80%;
  background-position: left center; 
  height: 165vh;
  width: 100vw; /* Updated to prevent overflow */
  top: 0; 
  left: 0;
  z-index: -1; 
}

.user-container {
  @apply max-w-lg mx-auto p-6 bg-custom-teal shadow-lg rounded-lg relative;
  margin-top: 2vh; 
  max-height: 150vh;
  overflow-y: auto; 
  position: absolute; 
  right: 0;
  top: 10vh;
  min-width: 320px; 
  width: calc(100vw - 320px); /* Ensure it stays within viewport */
}

.input {
  @apply w-full p-3 border border-gray-300 rounded-lg bg-custom-teal text-gray-800;
}

.form-group {
  @apply mb-4;
}

.user-container form {
  @apply flex flex-col;
}

.user-container label {
  @apply mb-2 text-gray-800;
}

.button-container {
  display: flex;
  gap: 50px; /* adjust the gap value to your desired spacing */
}

.button-container button {
  @apply mt-4 bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700;
  max-width: 120px; /* adjust the value to your desired width */
}

.back {
  @apply absolute inset-0 bg-cover bg-center bg-no-repeat;
  background-color: custom-teal; 
  transform: scaleX(-1);
  z-index: -1; 
}

.button {
  @apply transition duration-200 ease-in-out;
}

.button-login, .button-register {
  @apply bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700;
}

.input-container {
  @apply relative;
}

.message {
  @apply mt-4 text-center text-green-500;
}

/* Added to ensure no horizontal scrolling */
body {
  overflow-x: hidden;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
