/* FullCalendarComponent.css */

/* Toolbar styling */
.fc .fc-toolbar {
  background-color: #ffffff; /* White background for the toolbar */
  color: #4b0082; /* Dark purple text color */
  border-radius: 0.75rem; /* Rounded corners */
  border-bottom: 1px solid #dcdcdc; /* Light gray border for separation */
}

/* Day cell styling */
.fc .fc-daygrid-day {
  background-color: #f9f9f9; /* Light gray background for day cells */
  color: #4b0082; /* Dark purple text color */
  border-radius: 0.375rem; /* Rounded corners */
  border: 1px solid #e0e0e0; /* Light gray border for day cells */
}

.fc .fc-daygrid-day:hover {
  background-color: #e6e6fa; /* Lavender background on hover */
}

/* Event styling */
.fc .fc-event {
  background-color: #9370db; /* Medium purple for events */
  color: #ffffff; /* White text color for events */
  border-radius: 0.375rem; /* Rounded corners */
}

/* Button styling */
.fc .fc-button {
  background-color: #6a0dad; /* Dark purple for buttons */
  color: #ffffff; /* White text color for buttons */
  border: none;
  border-radius: 0.375rem; /* Rounded corners */
}

.fc .fc-button:hover {
  background-color: #4b0082; /* Darker purple for button hover */
  color: #ffffff; /* White text color on hover */
}

.fc .fc-button-primary {
  background-color: #6a0dad; /* Dark purple for primary buttons */
  color: #ffffff; /* White text color */
}

.fc .fc-button-primary:hover {
  background-color: #4b0082; /* Darker purple for primary button hover */
  color: #ffffff; /* White text color on hover */
}

/* Add color for yesterday's date */
.fc-daygrid-day.yesterday {
  background-color: #f4a261; /* Adjust to your preferred color */
  color: white;
}

/* Add color for the day before yesterday */
.fc-daygrid-day.day-before-yesterday {
  background-color: #2a9d8f; /* Adjust to your preferred color */
  color: white;
}

/* Add color for today's date */
.fc-daygrid-day.today {
  background-color: #4caf50; /* Adjust to your preferred color */
  color: white;
}
